import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import { Layout } from "../../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../../components/Seo/Seo";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { CallbackBanner } from "../../components/CallbackBanner";

import CleaningImg from "../../images/service-grid/fridge_clean.jpeg";
import MopIcon from "../../assests/svg/cleaning-mop.svg";

import { useStyles } from "../../pagesStyles/aboutUs.styling";

const DomesticCleaning = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Domestic Cleaning",
    },
  ];

  const listItems = [
    "Vacuuming",
    "Dusting and polishing",
    "Mopping",
    "Internal Windows",
    "Making of beds",
    "Emptying/Loading of dishwasher",
    "End of tenancy cleaning",
    "New build cleaning",
    "Holiday Let cleaning",
    "AirbnB cleaning"

  ];
  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="Domestic Cleaning" />
      <section style={{ padding: desktop ? 0 : "0 5px"  }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>Domestic Cleaning </strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Taking away the stress of keeping on top of the housework.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Our cleaners will come at a time to best suit you so not to
              interfere with your lifestyle.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              We feel it is important to have continuity of cleaners, we
              understand that seeing the same friendly face each time puts you
              at ease. Having a trustworthy relationship is also very important.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Using your equipment and preferred cleaning agents reduce cross
              contamination.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              We can provide services outside of personal home occupation including end of tenancy cleaning, new build cleaning and holiday let cleaning. Please contact us to find out more
            </Typography>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={CleaningImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <section style={{ padding: desktop ? "5rem 0" : "2rem 5px"  }}>
        <Typography variant="h5" gutterBottom>
          <strong>Services Include:</strong>
        </Typography>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="domestic cleaning services">
              {listItems.map((item, index) => {
                return (
                  index + 1 <= listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="domestic cleaning services continued" style={{ paddingTop: !desktop && 0 }}>
              {listItems.map((item, index) => {
                return (
                  index + 1 > listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
        </Grid>
      </section>
      <CallbackBanner/>
    </Layout>
  );
};

export default DomesticCleaning;
